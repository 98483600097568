body {
  background-color: #273348;
  color: white;
	margin: 0;
}

.home__body {
  display: grid;
  /* cursor: none; */
}

.canvas {
  z-index: -2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.main__home {
  display: grid;
	max-width: 155rem;
	padding: 0 4rem;
	margin: auto;
}

.splatter__container {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	z-index: -1;
	width: 100%;
	mix-blend-mode: overlay;
	overflow: hidden;
	max-height: 100vh;
	display: flex;
	justify-content: center;
	text-align:center
}

.splatter__canvas {
	margin-top: -20rem;
	margin-left: 25rem;
}

.home__splatter {
	margin-top: -20vw;
	margin-left: 10vw;
	width: 135vw;
	opacity: 0;
}

.home__content {
	grid-row: 1;
	grid-column: 1;
	display: grid;
	height: 100vh;
}

.logo {
  grid-column: 1/3;
	grid-row: 1;
	display: grid;
  margin-top: 7rem;
	max-width: 118.3rem;
	position: relative;
	justify-items: center;
  justify-self: center;
}

.logoWrapper {
	height: fit-content;
	display: grid;
	margin-top: 4rem;
}

.logo__img {
	justify-self: center;
	grid-row: 1;
	grid-column: 1;
	width: 80%;
	opacity: 0;
}

.logo__tag {
	grid-column: 1;
	grid-row: 1;
	font-family: omnes-pro, sans-serif;
	color: white;
	font-size: 2.4rem;
	text-transform: uppercase;
	font-weight: 100;
	letter-spacing: .3rem;
	justify-self: flex-end;
	align-self: flex-end;
	margin-right: 30rem;
	margin-bottom: 1rem;
	opacity: 0;
}

.home__intro {
  margin-left: 9vw;
  font-size: 2.4rem;
  line-height: 4.5rem;
  letter-spacing: .05em;
}

.home__navigation {
	grid-row: 1;
	grid-column: 1;
	display: grid;
	height: 100vh;
	opacity: 0;
}

.nav__home {
  margin-top: 3rem;
	display: grid;
	justify-content: center;
	align-items: flex-end;
	margin-bottom: 4vh;
}

.home__menu {
	grid-row: 1;
	grid-column: 1;
	list-style-type: none;
	display: grid;
	justify-items: center;
	grid-template-columns: repeat(5, max-content);
	z-index: 1;
}

.home__item {
  width: 23rem;
  height: 23rem;
  border-radius: 50%;
  border: white solid .1rem;
	color: white;
	text-decoration: none;
	transition: .25s ease-in-out;
	will-change: transform;
	overflow: hidden;
}

.home__item::after {
	display: block;
	content: '';
  height: 101%;
  width: 101%;
  position: absolute;
  left: 0;
  top: 0;
	transform-origin: top left;
	transition: ease-out .2s;
	will-change: transform;
	border-radius: 50%;
	z-index: -1;
	transform: translate(-100%, 50%);
	background-color: white;
	border: white solid .1rem;
}

.home__item:hover::after {
	transform: translate(-.5%, -.5%);
}

.home__item:hover {
	color: var(--blue);
	/* border: transparent solid .1rem; */
	/* background-color: white; */
}

.home__item1 {
	margin-top: 6rem;
}

.home__item2 {
  grid-row: 2;
  grid-column: 2;
	margin-top: -2.9rem;
	margin-left: -1rem;
}

.home__item3 {
	margin-top: 8rem;
	margin-left: 4rem;
  grid-column: 3;
}

.home__item4 {
	grid-column: 4;
	grid-row: 2;
	margin-top: -3.7rem;
	margin-left: 7rem;
}

.home__item5 {
	grid-column: 5;
	margin-top: 7rem;
	margin-left: 9rem;
}


.home__itemD {
  grid-row: 1;
}

.home__itemT {
  grid-row: 2;
  grid-column: 2;
}

.home__link {
  width: inherit;
  height: inherit;
  text-decoration: none;
  font-size: 2.4rem;
  letter-spacing: .1em;
  display: grid;
  justify-content: center;
  flex-flow: column;
}

.home__link div {
  display: grid;
  /* row-gap: 2.5rem; */
}

.link__text {
  grid-row: 1;
  grid-column: 1;
	font-family: melodrama;
	text-transform: lowercase;
	font-weight: 300;
  text-align: center;
  align-self: center;
}

.link__arrow {
  margin-top: 17rem;
  grid-row: 1;
  grid-column: 1;
	justify-self: center;
	opacity: 0;
	transition: .25s;
	transform: translate(-100%, 0);
}

.home__item:hover .link__arrow {
	opacity: 1;
	transform: translate(0, 0);
}

.home__item:hover .link__text {
	text-shadow: none;
}

.nav {
  position: fixed;
  margin-top: 3.8rem;
  margin-left: 9.9rem;
  font-size: 1.8rem;
  width: 85%;
}

.nav__menu {
  display: grid;
  grid-auto-flow: column;
  column-gap: 50%;
  align-items: center;
}

.menu__item--sec {
  display: grid;
  column-gap: 9.8rem;
  grid-auto-flow: column;
}

.item__link {
  text-decoration: none;
  color: white;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.item__link--contact {
  justify-self: flex-end;
}

@media only screen and (max-width: 1400px) {
	.logo__tag {
		margin-right: 23vw;
		font-size: 2.1rem;
		margin-bottom: 0rem;
	}

	.nav__home {
		margin-bottom: 10vh;
	}

	.link__arrow {
		margin-top: 12.5rem;
	}

	.home__item {
		width: 20rem;
		height: 20rem;
	}

	.home__item4 {
		margin-left: 3rem;
	}

	.home__item5 {
		margin-left: 3rem;
	}
}

@media only screen and (max-width: 1250px) {
	.logo {
		margin-top: 5rem;
	}

	.nav__home {
		margin-bottom: 15vh;
	}

	.home__item {
		width: 18rem;
		height: 18rem;
		background: var(--blue);
	}

	.home__link {
		font-size: 2rem;
	}

	.home__item1 {
		margin-top: 0;
	}

	.home__item2 {
		margin-top: 0;
	}

	.home__item3 {
		margin-top: 2rem;
		margin-left: 1rem;
	}

	.home__item4 {
		margin-top: 0;
		margin-left: 1rem;
	}

	.home__item5 {
		margin-top: 0;
		margin-left: 1rem;
	}
}

@media only screen and (max-width: 1050px) {
	.nav__home {
		margin-bottom: 10vh;
	}

	.home__menu {
		row-gap: 10vh;
		grid-template-columns: repeat(5, 15rem);
	}

	.home__item {
		background-color: white;
	}

	.link__text {
		color: black;
	}
}

@media only screen and (max-width: 890px) {
	.home__menu {
		grid-template-columns: repeat(5, 12.5rem);
	}
}

@media only screen and (max-width: 890px) {
	.logo__tag {
		font-size: 1.6rem;
	}

	.nav__home {
		margin-bottom: 12.5vh;
	}

	.home__menu {
		column-gap: 15vw;
		row-gap: 2rem;
		grid-template-columns: repeat(2, 12.5rem);
		grid-template-rows: repeat(5, 11vh);
	}

	.home__link {
		font-size: 1.8rem;
	}

	.link__arrow {
		margin-top: 9rem;
		width: 3.5rem;
	}

	.home__item1 {
	}

	.home__item2 {
		grid-row: 2;
		grid-column: 2;
	}

	.home__item3 {
		grid-row: 3;
		grid-column: 1;
		margin-left: 5rem;
		margin-top: 0;
	}

	.home__item4 {
		grid-row: 4;
		grid-column: 2;
	}

	.home__item5 {
		grid-row: 5;
		grid-column: 1;
	}
}

@media only screen and (max-width: 600px) {
	.home__item {
		height: 15rem;
		width: 15rem;
	}

	.logo__tag {
	}
}

@media only screen and (max-width: 400px) {
	.logo__tag {
		font-size: 1.2rem;
	}

	.home__menu {
		row-gap: 0;
	}

	.nav__home {
		margin-bottom: 15vh;
	}

	.home__item {
		height: 12rem;
		width: 12rem;
	}

	.home__link {
		font-size: 1.6rem;
	}
}

@media only screen and (max-width: 375px) {
}

