@font-face {
  font-family: 'author';
  src: url('../fonts/Author-Variable.woff2') format('woff2'),
       url('../fonts/Author-Variable.woff') format('woff'),
       url('../fonts/Author-Variable.ttf') format('truetype');
       font-weight: 200 700;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'author-italic';
  src: url('../fonts/Author-VariableItalic.woff2') format('woff2'),
       url('../fonts/Author-VariableItalic.woff') format('woff'),
       url('../fonts/Author-VariableItalic.ttf') format('truetype');
       font-weight: 200 700;
       font-display: swap;
       font-style: italic;
}

@font-face {
  font-family: 'sentient';
  src: url('../fonts/Sentient-Variable.woff2') format('woff2'),
       url('../fonts/Sentient-Variable.woff') format('woff'),
       url('../fonts/Sentient-Variable.ttf') format('truetype');
       font-weight: 200 700;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'sentient-italic';
  src: url('../fonts/Sentient-VariableItalic.woff2') format('woff2'),
       url('../fonts/Sentient-VariableItalic.woff') format('woff'),
       url('../fonts/Sentient-VariableItalic.ttf') format('truetype');
       font-weight: 200 700;
       font-display: swap;
       font-style: italic;
}

@font-face {
  font-family: 'satoshi';
  src: url('../fonts/Satoshi-Variable.woff2') format('woff2'),
       url('../fonts/Satoshi-Variable.woff') format('woff'),
       url('../fonts/Satoshi-Variable.ttf') format('truetype');
       font-weight: 300 900;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'satoshi-italic';
  src: url('../fonts/Satoshi-VariableItalic.woff2') format('woff2'),
       url('../fonts/Satoshi-VariableItalic.woff') format('woff'),
       url('../fonts/Satoshi-VariableItalic.ttf') format('truetype');
       font-weight: 300 900;
       font-display: swap;
       font-style: italic;
}

@font-face {
  font-family: 'ranade';
  src: url('../fonts/Ranade-Variable.woff2') format('woff2'),
       url('../fonts/Ranade-Variable.woff') format('woff'),
       url('../fonts/Ranade-Variable.ttf') format('truetype');
       font-weight: 100 700;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'ranade-italic';
  src: url('../fonts/Ranade-VariableItalic.woff2') format('woff2'),
       url('../fonts/Ranade-VariableItalic.woff') format('woff'),
       url('../fonts/Ranade-VariableItalic.ttf') format('truetype');
       font-weight: 100 700;
       font-display: swap;
       font-style: italic;
}

@font-face {
  font-family: 'cabinetgrotesk';
  src: url('../fonts/CabinetGrotesk-Variable.woff2') format('woff2'),
       url('../fonts/CabinetGrotesk-Variable.woff') format('woff'),
       url('../fonts/CabinetGrotesk-Variable.ttf') format('truetype');
       font-weight: 100 700;
       font-display: swap;
}

@font-face {
	font-family: 'justme';
	src:url('../fonts/KG-Just-Me-Again-Down-Here.ttf.woff') format('woff'),
			url('../fonts/KG-Just-Me-Again-Down-Here.ttf.svg#KG-Just-Me-Again-Down-Here') format('svg'),
			url('../fonts/KG-Just-Me-Again-Down-Here.ttf.eot'),
			url('../fonts/KG-Just-Me-Again-Down-Here.ttf.eot?#iefix') format('embedded-opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'melodrama';
  src: url('../fonts/Melodrama-Variable.woff2') format('woff2'),
       url('../fonts/Melodrama-Variable.woff') format('woff'),
       url('../fonts/Melodrama-Variable.ttf') format('truetype');
       font-weight: 300 700;
       font-display: swap;
       font-style: normal;
}

@font-face {
	font-family: 'renomono';
	src: url('../fonts/RenoMono.woff2') format('woff2'); /* Modern Browsers */
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

