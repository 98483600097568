.main {
	margin-top: 19rem;
}

.container {
	max-width: 152rem;
	margin: auto;
	padding: 0 7rem;
	margin-top: 12rem;
}

.section {
	display: grid;
	grid-auto-flow: row;
}

.section1 {
	justify-self: center;
}

.title {
	text-align: center;
	font-size: 32rem;
	line-height: 38rem;
	font-family: melodrama;
	letter-spacing: .05em;
	opacity: .1;
	align-self: center;
	justify-self: center;
}

.block {
	display: flex;
	flex-flow: column;
	width: 60%;
}

.sub {
	font-family: melodrama;
	font-size: 2.8rem;
	line-height: 5rem;
	letter-spacing: .03em;
	margin-bottom: 2.5rem;
}

.paragraph {
	font-size: 2.1rem;
	line-height: 3.4rem;
	letter-spacing: .03em;
}

.quote {
	margin-top: 25rem;
	margin-bottom: 15rem;
	font-size: 7.2rem;
	font-family: melodrama;
	line-height: 12rem;
	letter-spacing: .03em;
	text-align: center;
}

.block2 {
	justify-self: flex-end;
	margin-top: 14rem;
}

.block4 {
	justify-self: flex-end;
	margin-top: 14rem;
	margin-bottom: 15rem;
}

@media only screen and (max-width: 1660px) {
	.title {
		font-size: 18.22vw;
		line-height: 20.8vw;
	}
}


@media only screen and (max-width: 1400px) {
	.block {
		width: 65%;
	}
}

@media only screen and (max-width: 1250px) {
	.sub {
		font-size: 2rem;
		line-height: 3.2rem;
	}

	.paragraph {
		font-size: 1.6rem;
		line-height: 3rem;
	}
}

@media only screen and (max-width: 1050px) {
	.container {
		margin-top: 8rem;
	}

	.block {
		width: 75%;
	}

	.block2 {
		margin-top: 10rem;
	}

	.block4 {
		margin-top: 10rem;
		margin-bottom: 10rem;
	}

	.quote {
		margin-top: 16rem;
		margin-bottom: 16rem;
	}
}

@media only screen and (max-width: 890px) {
	.main {
		margin-top: 13rem;
	}

	.container {
		margin-top: 7rem;
	}

	.block {
		width: 80%;
	}
}

@media only screen and (max-width: 744px) {
	.block {
		width: 100%;
	}
}

@media only screen and (max-width: 600px) {
	.main {
		margin-top: 12rem;
	}

	.container {
		padding: 0 2rem;
	}

	.block2 {
		margin-top: 6rem;
	}

	.block4 {
		margin-top: 6rem;
	}

	.quote {
		font-size: 10vw;
		line-height: 14vw;
		margin-top: 12rem;
		margin-bottom: 12rem;
	}
}

@media only screen and (max-width: 400px) {
	.quote {
		margin-top: 8rem;
		margin-bottom: 8rem;
	}
}
