.navigation {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	top: 0;
	z-index: 2;
	transition: .3s ease-in-out;
}

.nav__menu {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 4.8rem;
	margin-left: 11.5rem;
	margin-right: 11.5rem;
}

.nav__items {
	height: 5.3rem;
	justify-self: flex-end;
	font-size: 1.8rem;
	letter-spacing: 0.02em;
	display: flex;
	align-items: center;
}

.nav__itemsIndent {
	margin-right: -1.5rem;
}

.items {
	grid-column: 1;
	grid-row: 1;
	display: grid;
	column-gap: 4rem;
	grid-auto-flow: column;
	margin-right: 4.5rem;
	justify-self: flex-end;
	align-items: center;
}

.center {
	display: flex;
	gap: 1.5rem;
}

.brick {
	height: 18rem;
	width: 8rem;
	margin-top: -4rem;
	background: #8591A5;
	display: flex;
}

.brick img {
	justify-self: center;
	align-self: flex-end;
	margin-bottom: 2.5rem;
}

.brickTag {
	opacity: 0;
	position: absolute;
}

.brick :hover {
	cursor: pointer;
	transform: translateY(-4rem);
}

.item {
	text-decoration: none;
	padding-top: .7rem;
	padding-bottom: .7rem;
	border-bottom: .1rem solid transparent;
	color: black;
}

.active {
	text-decoration: none;
	font-weight: 300;
	border-bottom: .1rem solid black;
}

.activeDark {
	border-bottom: .1rem solid white;
}

.activeRed {
	border-bottom: .1rem solid var(--red);
}

.right {
}

.item__red {
	color: var(--red);
	font-weight: 400;
	/* text-transform: uppercase; */
	letter-spacing: .05em;
}

.line {
	height: 3.9rem;
	width: max-content;
	margin-left: 4.5rem;
	margin-right: 3rem;
	border-left: .1rem black solid;
}

.menu {
	justify-self: flex-end;
	background-color: var(--blue);
	height: 8rem;
	width: 8rem;
	display: grid;
	align-items: center;
	justify-items: center;
	border-radius: 50%;
	font-size: 1.6rem;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	transition: .2s ease-in-out;
}

.menu:hover {
	/* cursor: pointer; */
	width: 40rem;
	height: 40rem;
	position: absolute;
	top: 2rem;
	right: 5rem;
}


.sound {
	/* position: absolute; */
	grid-column: 1;
	grid-row: 1;
}

.soundWrapper {
	outline: none;
	background: transparent;
	border: none;
	z-index: 2;
	height: 5rem;
	width: 15rem;
	background-color: white;
	border-radius: 6rem;
	display: flex;
}

.soundContent {
	display: grid;
	align-items: center;
	height: 100%;
	position: relative;
	flex: 1;
}

.soundTag {
	text-transform: uppercase;
	font-size: 1.2rem;
	letter-spacing: .05em;
	color: black;
	justify-self: flex-start;
	transition: .3s ease-out;
}

.soundTag2 {
	margin-left: 5rem;
}

.soundTag1 {
	margin-left: 2.1rem;
}

.soundCircle {
	position: absolute;
	right: 0;
	width: 4.2rem;
	height: 4.2rem;
	border-radius: 50%;
	background-color: var(--beige);
	transition: .3s ease-out;
}

.soundCircleDark {
	background-color: #475D83;
}

.soundCircle2 {
	right: 9.6rem;
}

.soundIcon {
	position: absolute;
	right: .8rem;
	opacity: 0;
	transition: 0s;
}

.soundIconHome {
	stroke: black;
	fill: black;
}

.soundIcon2 {
	right: auto;
	left: .8rem;
}

.soundIconVisible {
	opacity: 1;
	transition: .3s ease-in-out;
}

.hamburgerContainer {
	display: none;
}

.hamburger {
	position: relative;
	background-color: white;
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;
	border: none;
	outline: none;
}

.hamburger div {
	position: absolute;
	width: 3rem;
	height: .2rem;
	background-color: black;
	border-radius: 5rem;
	transform-origin: 50% 50%;
	transition: .2s ease-in-out;
}

.hamburgerFirst {
	transform: translateY(-.4rem);
}

.hamburgerSecond {
	transform: translateY(.4rem);
}

.hamburgerClose1 {
	transform: rotate(45deg);
}

.hamburgerClose2 {
	transform: rotate(-45deg);
}

.background {
	display: none;
}

@media only screen and (max-width: 1400px) {
	.nav__items {
		font-size: 1.6rem;
	}
}

@media only screen and (max-width: 1250px) {
	.items {
		column-gap: 3rem;
	}
}

@media only screen and (max-width: 1024px) {
	.nav__menu {
		align-items: flex-start;
	}

	.nav__items {
		display: none;
		height: max-content;
		margin-top: 8vh;
		font-size: 4.5rem;
		text-transform: uppercase;
		flex-flow: column;
	}

	.nav__itemsIndent {
		margin-right: 0;
	}

	.items {
		margin-right: 0;
		grid-auto-flow: row;
		order: 2;
	}

	.active {
		border-bottom: solid white .15rem;
	}

	.item {
		margin-bottom: 7vh;
		color: white;
		padding-bottom: 1rem;
	}

	.sound {
		order: 0;
		margin-bottom: 10vh;
	}

	.soundCircle {
		background-color: #475D83;
	}

	.soundIcon :nth-child(1) {
		stroke: white;
	}

	.soundIcon :nth-child(2) {
		fill: white;
	}

	.navigation {
		position: fixed;
	}

	.navigationHamburger {
		z-index: 4;
		height: 100vh;
	}

	.nav__itemsHamburger {
		display: flex;

	}

	.hamburgerContainer {
		display: initial;
		justify-self: flex-end;
	}

	.background {
		display: initial;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 0;
		z-index: -1;
		opacity: 0;
		transition: .2s ease-out;
	}

	.backgroundActive {
		opacity: 1;
		background-color: var(--blue);
		height: 100vh;
	}
}

@media only screen and (max-width: 890px) {
	.nav__menu {
		margin-left: 7rem;
		margin-right: 7rem;
		margin-top: 3.6rem;
	}
}

@media only screen and (max-width: 744px) {
}

@media only screen and (max-width: 600px) {
	.nav__menu {
		margin-left: 8vw;
		margin-right: 8vw;
	}

	.nav__items {
		position: fixed;
		width: 80%;
		text-align: right;
		font-size: 4rem;
		margin-top: 10vh;
	}

	.items {
		align-self: flex-end;
	}

	.item {
		margin-bottom: 5.5vh;
	}

	.sound {
		margin-top: .5rem;
		margin-left: 1.5rem;
		margin-bottom: 5rem;
		align-self: flex-start;
	}


	.logo {
		width: 3rem;
		height: 4rem;
		transition: .4s ease-in-out;
	}

	.logoContainer {
		align-self: center;
	}

	.hamburger {
		width: 5rem;
		height: 5rem;
	}

	.hamburger div {
		width: 2.5rem;
	}
}

@media only screen and (max-width: 400px) {
	.nav__menu {
		margin-top: 3rem;
	}

	.nav__items {
		font-size: 3.5rem;
	}

}

