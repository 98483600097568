@import url("./css/fonts.css");

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

.p5Canvas {
  z-index: -1;
  position: absolute;
}

code {
}

#root {
	font-family: satoshi, sans-serif, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	font-weight: 300
}
