.main {
	composes: main from './Yoga.module.css';
}

.header {
	composes: header from './Yoga.module.css';
}

.title {
	composes: title from './Yoga.module.css';
}

.content {
	composes: content from './Yoga.module.css';
	grid-area: 1 / 1 / 2 / 2;
	height: fit-content;
}

.sub {
	composes: sub from './Yoga.module.css';
}

.paragraph {
	composes: paragraph from './Yoga.module.css';
}

.part1 {
	display: grid;
	grid-auto-flow: column;
	margin-top: 3rem;
	margin-bottom: 18vw;
	margin-left: 3vw;
}

.text__part1 {
	display: grid;
}

.sub1 {
	width: 60.5rem;
	margin-bottom: 2rem;
}

.paragraph1 {
	width: 65.2rem;
	margin-bottom: 12rem;
}

.sub2 {
	width: 54.4rem;
	margin-bottom: 2rem;
	margin-left: 16rem;
}

.paragraph2 {
	width: 61.1rem;
	margin-left: 16rem;
}

.part2 {
	margin-left: 25.8vw;
	margin-bottom: 7.1rem;
	display: flex;
	flex-flow: column;
	row-gap: 8rem;
}

.bach__title {
	color: white;
	font-family: melodrama;
	font-weight: 100;
	font-size: 5rem;
	text-transform: uppercase;
	margin-bottom: -4rem;
}

.bach__title strong {
	font-weight: 500;
	letter-spacing: 0;
	text-transform: initial;
}

.bach__titleClosed {
	color: black;
	margin-bottom: 0;
	margin-top: 4rem;
}

.meerTag {
	margin-top: -4rem;
	font-size: 2rem;
	font-weight: 200;
	letter-spacing: .05em;
}

.meerBach {
	width: 40.6rem;
	height: 40.6rem;
	border-radius: 50%;
	background-color: white;
	border: none;
	outline: none;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	justify-self: center;
	margin-bottom: 20rem;
}

.paragraph3 {
	width: 60.3rem;
}

.paragraph4 {
	width: 71.4rem;
}

.part2__text2 {
	display: flex;
	flex-flow: column;
	margin-left: 15.6vw;
	row-gap: 1.5rem;
}

.paragraph5 {
	width: 56.6rem;
}

.paragraph__star {
	width: 50.1rem;
	font-family: justme;
	letter-spacing: .05em;
	font-size: 2.8rem;
}

.part3 {
	grid-area: 1 / 1 / 2 / 2;
	display: grid;
	overflow: hidden;
}

.img__intro {
	justify-self: flex-end;
	margin-right: -2vw;
	margin-top: 25rem;
}

.img__bach {
	margin-top: 20rem;
	transition: ease-out .3s;
}

@media only screen and (max-width: 1660px) {
	.img__intro {
		width: 70rem;
		margin-right: -10rem;
	}

	.sub {
		width: 52rem;
	}

	.paragraph {
		width: 55rem;
	}

	.sub1 {
		width: 50rem;
	}

	.sub2 {
		margin-left: 0;
	}

	.paragraph2 {
		margin-left: 0;
	}
}

@media only screen and (max-width: 1400px) {
	.title {
		font-size: 18rem;
	}

	.img__intro {
		width: 60rem;
		margin-top: 30rem;
		margin-right: -10rem;
	}

	.img__bachScoot {
		margin-left: -20rem;
	}
}

@media only screen and (max-width: 1250px) {
	.img__intro {
		width: 55rem;
		/* margin-top: 35rem; */
	}

	.sub {
		width: 41.6vw;
	}

	.paragraph {
		width: 44vw;
	}

	.meerBach {
		width: 32rem;
		height: 32rem;
	}

	.meerTag {
		font-size: 1.8rem;
		margin-top: -2rem;
	}

	.bach__title {
		font-size: 3.8rem;
		margin-top: 2rem;
	}

	.img__bach {
		width: 50rem;
	}

	.part2 {
		margin-left: 20rem;
	}

	.part2__text2 {
		margin-left: 10rem;
	}

	.sub2 {
		margin-left: 5vw;
	}

	.paragraph2 {
		margin-left: 5vw;
	}
}

@media only screen and (max-width: 1050px) {
	.title {
		font-size: 15rem;
	}

	.img__intro {
		width: 50rem;
		margin-right: -15rem;
	}

	.sub {
		width: 47vw;
	}

	.paragraph {
		width: 47vw;
	}

	.paragraph1 {
		margin-bottom: 8rem;
	}

	.sub2 {
		margin-left: 7vw;
	}

	.paragraph2 {
		margin-left: 7vw;
	}

	.img__bach {
		width: 45rem;
	}
}

@media only screen and (max-width: 890px) {
	.header {
		margin-bottom: 2rem;
	}

	.title {
		font-size: 12rem;
	}

	.img__intro {
		width: 45rem;
		margin-top: 18rem;
	}

	.sub2 {
		margin-left: 5vw;
		width: 45rem;
	}

	.paragraph2 {
		margin-left: 5vw;
		width: 45rem;
	}

	.part2 {
		margin-left: 15rem;
	}

	.part2__text2 {
		margin-left: 0;
	}

	.paragraph3 {
		width: 80%;
	}

	.paragraph4 {
		width: 80%;
	}

	.paragraph5 {
		width: 80%;
	}
}

@media only screen and (max-width: 744px) {
	.header {
		margin-top: 3rem;
		margin-bottom: 1rem;
	}

	.title {
		font-size: 11rem;
	}

	.sub {
		width: 70vw;
	}

	.paragraph {
		width: 75vw;
	}

	.paragraph1 {
		margin-bottom: 60rem;
	}

	.img__intro {
		margin-top: 55rem;
		margin-right: -10rem;
		justify-self: center;
	}

	.sub2 {
		margin-left: 0;
	}

	.paragraph2 {
		margin-left: 0;
	}

	.part2 {
		margin-left: 15rem;
	}

	.paragraph3 {
		width: 90%;
	}

	.paragraph4 {
		width: 90%;
	}

	.paragraph5 {
		width: 90%;
	}

	.paragraph__star {
		width: 90%;
	}

	.img__bach {
		margin-top: 25rem;
	}
}

@media only screen and (max-width: 600px) {
	.title {
		font-size: 8rem;
	}

	.part1 {
	}

	.sub {
		width: 100%;
	}

	.paragraph {
		width: 100%;
	}

	.img__intro {
		margin-top: 40rem;
		justify-self: center;
	}

	.img__bach {
		margin-top: 10rem;
	}

	.part3 {
		position: relative;
		overflow: hidden;
		width: 100%;
	}

}

@media only screen and (max-width: 400px) {
	.title {
		font-size: 20vw;
	}

	.part1 {
		margin-bottom: 40rem;
	}

	.img__intro {
		margin-top: 52rem;
		width: 40rem;
		margin-right: 0;
		justify-self: center;
	}

	.img__bach {
		width: 40rem;
	}

	.meerBach {
		width: 80vw;
		height: 80vw;
	}

	.bach__title {
		font-size: 9.5vw;
	}

	.meerTag {
		font-size: 4.5vw;
	}
}

@media only screen and (max-width: 375px) {
	.img__intro {
		margin-top: 65rem;
	}

	.img__bach {
		width: 35rem;
	}
}
