.footer {
	height: 100vh;
	background-color: var(--blue);
	font-size: 1.8rem;
	margin-top: 5rem;
}

.footerContainer {
	display: grid;
	grid-template-columns: auto auto 1fr;
	padding-top: 15rem;
	padding-left: 10rem;
}

.logo {
	display: grid;
	justify-items: center;
	row-gap: 6.1rem;
}

.copyright {
	grid-column: 1;
	justify-self: center;
	font-family: satoshi, sans-serif;
	margin-top: 30vh;
}

.info {
	width: 44.5vw;
	margin-left: 15rem;
}

.info__menu {
	display: grid;
	height: 43.5rem;
}

.info__item {
	border-top: black .1rem solid;
	display: grid;
	grid-auto-rows: min-content;
	align-content: center;
	padding-left: 5rem;
	row-gap: .8rem;
}

.item__white {
	border-top: white .1rem solid;
	color: white;
}

.info__tag {

}

.piece {
	font-family: satoshi;
	font-size: 2.5rem;
	font-weight: 400;
	letter-spacing: 0.08em;
}

.footer__nav {
	margin-right: 12rem;
	justify-self: flex-end;
	width: 17.8vw;
	border-top: .1rem black solid;
}

.nav__menu {
	display: grid;
	grid-auto-flow: row;
	justify-content: flex-end;
	margin-top: 1.6rem;
	margin-right: 1.1rem;
	row-gap: 1rem;
}

.nav__item {
	display: grid;
	row-gap: 1rem;
}

.item__type {
	text-transform: uppercase;
	text-decoration: none;
	color: black;
	letter-spacing: .08em;
}

.type__white {
	color: white;
}

.item__bold {
	font-weight: 500;
}

.line {
	width: 6.6rem;
	border-top: .1rem black solid;
}

.white__border {
	border-top: .1rem white solid;
}

@media only screen and (max-width: 1660px) {
	.footer__nav {
		margin-right: 8rem;
	}
}

@media only screen and (max-width: 1250px) {
	.footer__nav {
		margin-right: 5rem;
	}
}

@media only screen and (max-width: 1024px) {
	.info {
		width: 39vw;
	}

	.info__menu {
		height: 35rem;
	}

	.info__item {
		padding-left: 3rem;
	}

	.piece {
		font-size: 2rem;
	}
}

@media only screen and (max-width: 1250px) {
	.footer {
		grid-template-columns: none;
		grid-auto-flow: row;
		margin-left: 0;
		height: max-content;
	}

	.footerContainer {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: repeat(3, auto);
		row-gap: 3rem;
		padding-left: 0;
		justify-self: center;
	}

	.logo {
		grid-row: 2;
		align-self: flex-end;
		margin-left: 8rem;
	}

	.info {
		width: 80%;
		margin-left: 3rem;
		grid-column: 1/3;
	}

	.info__menu {
		height: 31rem;
		padding: 0 2.4rem;
	}

	.info__tag {
		font-size: 1.4rem;
	}

	.piece {
		font-size: 1.8rem;
	}

	.footer__nav {
		width: 26rem;
		grid-row: 2;
	}

	.nav__menu {
		row-gap: 2.5rem;
	}

	.nav__item {
		font-size: 2rem;
	}

	.logo svg {
		transform: rotate(90deg);
	}

	.copyright {
		margin-top: -10rem;
		grid-column: 1/3;
	}
}

@media only screen and (max-width: 890px) {
	.footer {
	}

	.footerContainer {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(3, auto);
		row-gap: 3rem;
		padding-left: 0;
		justify-self: center;
	}

	.logo {
		grid-row: 3;
		margin-left: 0;
	}

	.copyright {
		margin-top: -15rem;
	}
}

@media only screen and (max-width: 600px) {
	.info {
		justify-self: center;
		justify-items: center;
		margin: 0;
	}

	.info__item {
		padding-left: 0;
	}

	.logo svg {
		/* width: 8; */
		height: 80vw;
	}
}

@media only screen and (max-width: 400px) {
	.info {
		margin-left: 0;
		width: initial;
	}

	.piece {
		font-size: 1.6rem;
	}

	.footer__nav {
		margin-right: 5vw;
	}
}
