.main {

}

.header {
	composes: header from './Yoga.module.css';
	/* margin-bottom: 3rem; */
}

.title {
	composes: title from './Yoga.module.css';
	/* width: 20rem; */
}

.content {
	position: relative;
	color: white;
	composes: content from './Yoga.module.css';
}

.voet {
	position: absolute;
	top: 20rem;
	right: -5.333vw;
}

.part1 {
	margin-left: 7vw;
	margin-top: 4rem;
	display: flex;
	flex-flow: column;
	gap: 2rem;
}

.sub {
	composes: sub from './Yoga.module.css';
}

.paragraph {
	composes: paragraph from './Yoga.module.css';
}

.sub1 {
	width: 69rem;
}

.paragraph1 {
	width: 55.6rem;
}

.part2 {
	margin-top: 30rem;
	display: flex;
	gap: 13.7rem;
	margin-left: 10vw;
	margin-bottom: 20rem;
}

.part2Nav {
	display: grid;
	grid-template-rows: 2.4rem 22.5rem 2.4rem 21rem 2.4rem 19rem 2.4rem;
	justify-items: center;
	margin-top: 11rem;
}

.navPart {
	position: relative;
}

.navPart__mobile {
	display: none;
}

.navLine {
	width: .1rem;
	border-left: .1rem solid white;
}

.navBall {
	width: 2.4rem;
	height: 2.4rem;
	background-color: white;
	border-radius: 50%;
}

.navTag {
	position: absolute;
	width: max-content;
	top: 4rem;
	left: 50%;
	transform: translateX(-50%);
	background-color: var(--blue);
	font-size: 1.4rem;
	font-family: sentient;
	text-transform: uppercase;
	letter-spacing: .1em;
}

.part2Text {
	display: flex;
	flex-direction: column;
	gap: 7.5rem;
	width: 55.6rem;
}

.subTitle {
	font-family: melodrama;
	font-weight: 200;
	font-size: 5rem;
	margin-bottom: -2.5rem;
	letter-spacing: .05em;
}

.meerTag {
	margin-top: -4rem;
	font-size: 2rem;
	font-weight: 200;
	letter-spacing: .05em;
}

.meer {
	width: 40.6rem;
	height: 40.6rem;
	border-radius: 50%;
	background-color: white;
	border: none;
	outline: none;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	justify-self: center;
	/* margin-bottom: 20rem; */
}

.part3 {
	margin: auto;
	margin-top: 5rem;
	width: 74.9rem;
}

.part3Title {
	color: white;
	font-family: melodrama;
	font-weight: 100;
	font-size: 5rem;
	letter-spacing: .1em;
	margin-bottom: 4.8rem;
}

.part3TitleClosed {
	color: black;
	margin: 0;
	font-size: 4rem;
	margin-top: 3rem;
}

.part3Text {
	display: grid;
	justify-content: center;
	gap: 3rem;
}

@media only screen and (max-width: 1660px) {
	.title {
		font-size: 18rem;
	}

	.part1 {
		margin-left: 5vw;
	}

	.voet {
		top: 40rem;
	}

	.voet img {
		width: 45vw;
	}
}

@media only screen and (max-width: 1250px) {
	.title {
		font-size: 15rem;
	}

	.part1 {
		margin-left: 1vw;
	}

	.voet {
		top: 35rem;
	}

	.voet img {
		width: 50vw;
	}

	.part2 {
		margin-left: 8vw;
		margin-bottom: 12rem;
	}

	.meer {
		width: 32rem;
		height: 32rem;
	}

	.meerTag {
		font-size: 1.8rem;
		margin-top: -2rem;
	}

	.part3Title {
		font-size: 3.8rem;
		margin-top: 2rem;
	}
}

@media only screen and (max-width: 1050px) {
	.voet img {
		width: 50rem;
	}
}

@media only screen and (max-width: 1024px) {
	.title {
		font-size: 12rem;
	}

	.part1 {
		margin-top: 1rem;
	}

	.sub1 {
		width: 47rem;
	}

	.paragraph1 {
		width: 45rem;
	}

	.part2 {
		margin-left: 1rem;
		gap: 10rem;
		justify-self: center;
	}

	.voet {
		top: 30rem;
		right: -5vw;
	}
}

@media only screen and (max-width: 890px) {
	.title {
		font-size: 14.5vw;
	}

	.sub1 {
		width: 42rem;
	}

	.paragraph1 {
		width: 42rem;
	}

	.part2Text {
		width: 50vw;
	}

	.voet {
		right: -7vw;
	}

	.voet img {
		width: 50vw;
	}

	.part3 {
		width: 75vw;
	}
}

@media only screen and (max-width: 744px) {
	.part1 {
		margin-top: 0;
	}

	.sub {
		width: 80%;
	}

	.paragraph1 {
		width: 80%;
	}

	.part2 {
		margin-top: 5rem;
		justify-content: center;
		position: relative;
		margin-left: 0;
		width: 75vw;
		margin-bottom: 5rem;
	}

	.part2Text {
		z-index: 1;
		width: 100%;
		align-items: center;
		gap: 1.5rem;
	}

	.paragraphPart2 {
		background-color: var(--blue);
		padding: 1.5rem .5rem;
		width: 100%;
	}

	.subTitle {
		margin-bottom: 4rem;
		font-size: 4rem;
	}

	.part2Nav {
		position: absolute;
		margin-top: 8rem;
		/* grid-template-rows: 2.4rem 27.5rem 2.4rem 24.5rem 2.4rem 21.5rem 2.4rem; */
		grid-template-rows: none;
		height: 81%;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-items: center;
		display: none;
	}

	.navPart {
		flex-grow: 0;
	}

	.navPart__mobile {
		display: initial;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-items: flex-start;
		gap: 1.5rem;
		height: 9rem;
	}

	.navPart__mid .navBall {
		margin-top: 1.5rem;
	}

	.navLine {
		height: 100%;
		position: absolute;
	}

	.navTag {
		position: initial;
		z-index: 1;
		left: 0;
		transform: translateX(0);
	}

	.navLine {
		flex-grow: 1;
		height: 100%;
	}

	.voet {
		position: initial;
		grid-row: 3;
		margin-top: 5rem;
	}

	.voet img {
		width: 75vw;
	}

	.part3 {
	}
}

@media only screen and (max-width: 600px) {
	.sub1 {
		width: 100%;
	}

	.paragraph1 {
		width: 100%;
	}

	.part2 {
		width: 95%;
	}

	.part3 {
		width: 95%;
	}

}

@media only screen and (max-width: 400px) {
	.subTitle {
		font-size: 3rem;
	}

	.meer {
		width: 80vw;
		height: 80vw;
	}

	.part3Title {
		font-size: 9.5vw;
	}

	.meerTag {
		font-size: 4.5vw;
	}
}
