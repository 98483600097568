.main {
	max-width: 152rem;
	margin: auto;
	margin-top: 22rem;
}

.section {
	display: grid;
}

.titleBG {
	grid-row: 1;
	grid-column: 1;
	position: absolute;
	font-family: melodrama;
	font-size: 45rem;
	text-align: right;
	justify-self: center;
	z-index: -1;
	opacity: .05;
}

.part__intro {
	grid-row: 1;
	grid-column: 1;
	margin-top: 18rem;
	margin-left: 7rem;
	height: min-content;
	display: flex;
	flex-flow: row wrap;
	row-gap: 5rem;
	justify-self: center;
}

.title {
	font-family: melodrama;
	font-size: 15rem;
	font-weight: 500;
	letter-spacing: .05em;
	color: var(--blue);
	flex-shrink: 1;
	flex-basis: 100%;
	margin-left: 1.8rem;
}

.chrono {
	font-size: 1.6rem;
	margin-right: 1.8rem;
}

.sub {
	font-family: melodrama;
	font-size: 2.8rem;
	line-height: 5rem;
	letter-spacing: .03em;
}

.paragraph {
	font-size: 2.1rem;
	line-height: 3.4rem;
	letter-spacing: .03em;

}

.sub1 {
	width: 80rem;
}

.img1 {
	grid-row: 1;
	grid-column: 1;
	z-index: -2;
}

.part__disciplines {
	padding-top: 25rem;
	padding-bottom: 10rem;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}

.quote {
	font-family: melodrama;
	font-size: 3.75vw;
	/* flex: 1 35%; */
	width: 47vw;
	line-height: 6vw;
	text-align: right;
}

.strong {
	color: var(--red);
	font-weight: 500;
}

.imgDisciplines {
	width: 30%;
	height: 30%;
	align-self: center;
}

.paragraph3 {
	padding-top: 7.5rem;
	width: 60rem;
}

.paragraph4 {
	width: 60rem;
	font-family: justme;
	font-size: 3rem;
	/* line-height: initial; */
}

.part__samen {
	margin-top: 18rem;
}

.subtitle {
	font-family: melodrama;
	font-size: 20rem;
	font-weight: 400;
	letter-spacing: .05em;
	color: var(--blue);
	margin-bottom: 12rem;
}

.samenContainer {
	display: flex;
}

.sub2 {
	margin-bottom: 7.5rem;
	width: 60vw;
}

.paragraph5 {
	margin-right: 7.5rem;
}

.paragraph7 {
	margin-top: 5.5rem;
	margin-bottom: 2rem;
	font-size: 2rem;
	font-style: italic;
	font-weight: 400;
	color: var(--red);
	text-align: center;
	align-self: center;
}

.part__nav {
	width: 99.2vw;
	margin-top: 25rem;
	height: 48rem;
	border-top: .1rem black solid;
	border-bottom: .1rem black solid;
	display: flex;
	flex-flow: column;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	overflow: hidden;
}

.banner {
	margin-top: 2.5rem;
	margin-bottom: 10rem;
	display: flex;
	position: absolute;
	text-decoration: none;
	color: black;
	justify-content: flex-start;
	align-items: center;
  white-space: nowrap;
	top: 0;
	left: 0;
	width: 100%;
  height: 100%;
  transition: all 1s ease;
}

/* .banner * {
	flex: 1;
} */

.scrollContainer {
	display: flex;
	align-items: center;
	animation: scrollText 25s infinite linear;
}

.scrollContainer * {
	flex:0 0 auto;
	margin-right: 4rem;
	margin-left: 4rem;
}

@keyframes scrollText {
  from   { transform: translateX(0%); }
  to { transform: translateX(-50%); }
}

.navItem {
	font-size: 20rem;
	font-weight: 200;
	color: var(--blue);
	flex-grow: 1;
	transition: all .1s ease;
}

.banner:hover .navItem {
	color: var(--red);
}

.navTag {
	margin: 0;
	transition: all 2s ease;
	font-family: melodrama;
	letter-spacing: .05em;
	font-weight: 600;
	width: 20rem;
	height: 20rem;
	border-radius: 50%;
	background: white;
	display: grid;
	justify-content: center;
	align-items: center;
}

@media only screen and (max-width: 1660px) {
	.main {
		max-width: 120rem;
		padding: 0 4rem;
	}

	.titleBG {
		font-size: 28vw;
	}

	.subtitle {
		font-size: 15rem;
	}
}

@media only screen and (max-width: 1400px) {
	.main {
		max-width: 100rem;
		margin-top: 18rem;
	}

	.part__intro {
		margin-left: 0;
	}

	.sub {
		font-size: 3rem;
		line-height: 5.5rem;
	}

	.sub1 {
		width: 80rem;
	}

	.paragraph3 {
		padding-bottom: 1.5rem;
	}
}

@media only screen and (max-width: 1250px) {
	.main {
		max-width: 80rem;
	}

	.part__intro {
		margin-top: 15rem;
	}

	.title {
		font-size: 12rem;
	}

	.sub1 {
		width: 95%;
	}

	.part__disciplines {
		flex-flow: column;
	}

	.imgDisciplines {
		align-self: flex-start;
		width: 37.7rem;
		height: 34.5rem;
		margin-top: 10rem;
	}

	.quote {
		order: -1;
		font-size: 5rem;
		line-height: 8rem;
		width: 62.5rem;
		align-self: flex-end;
	}

	.paragraph3 {
		width: 45rem;
	}

	.paragraph4 {
		width: 45rem;
	}

	.subtitle {
		width: 60rem;
	}

	.navItem {
		font-size: 12rem;
	}
}

@media only screen and (max-width: 1024px) {
	.main {
		max-width: 70rem;
	}

	.sub {
		font-size: 3rem;
		line-height: 5rem;
	}

	.title {
		font-size: 12rem;
	}

	.sub1 {
		width: 65rem;
	}

	.samenContainer2 {
		flex-flow: column;
	}

	.paragraph5 {
		margin-right: 0;
		margin-bottom: 5rem;
	}
}

@media only screen and (max-width: 890px) {
	.main {
		margin-top: 15rem;
	}

	.part__intro {
		margin-top: 12rem;
	}

	.title {
		font-size: 10rem;
	}

	.sub1 {
		width: 95%;
	}

	.sub2 {
		width: 100%;
	}

	.part__samen {
		padding: 0 5rem;
		margin-top: 12rem;
	}

	.part__nav {
		height: 29.5rem;
	}

	.paragraph7 {
		margin-top: 4rem;
	}

	.navItem {
		font-size: 10rem;
	}

	.navTag {
		width: 15rem;
		height: 15rem;
		font-size: 1.8rem;
	}

	.paragraph7 {
		font-size: 1.6rem;
		line-height: 150%;
	}
}

@media only screen and (max-width: 744px) {
	.part__disciplines {
		padding-top: 15rem;
	}

	.quote {
		width: 85%;
		line-height: 12vw;
	}

	.subtitle {
		font-size: 12rem;
		width: 80vw;
	}

	.part__samen {
		padding: 0 2rem;
	}

	.part__nav {
		margin-top: 15rem;
	}

	.scrollContainer * {
		margin: 0 2rem;
	}

	.navItem {
		font-size: 8rem;
	}

	.navTag {
		width: 12rem;
		height: 12rem;
		font-size: 1.6rem;
	}
}

@media only screen and (max-width: 600px) {
	.main {
		padding: 0 2rem;
		margin-top: 12rem;
	}

	.part__intro {
		margin-top: 6rem;
	}

	.title {
		font-size: 22vw;
	}

	.sub {
		font-size: 2rem;
		line-height: 3.2rem;
	}

	.sub1 {
		width: 90%;
	}

	.sub2 {
	}

	.quote {
		padding-right: 2rem;
		font-size: 6.67vw;
		line-height: 10vw;
	}

	.imgDisciplines {
		align-self: center;
		width: 80%;
		height: 80%;
		margin-top: 7.5rem;
	}

	.disciplinesUitleg {
		align-self: center;
	}

	.subtitle {
		font-size: 10rem;
		width: 92vw;
	}

	.paragraph3 {
		width: 75vw;
	}

 	.paragraph4 {
		width: 75vw;
	}

	.part__samen {
		margin-top: 10rem;
	}

	.subtitle {
		margin-bottom: 7.5rem;
	}

	.part__samen {
		padding: 0 1rem;
	}

	.samenContainer2 {
		padding: 0 2rem;
	}

	.paragraph5 {
		font-size: 1.6rem;
		line-height: 3rem;
	}

	.paragraph6 {
		font-size: 1.6rem;
		line-height: 3rem;
	}

	.part__nav {
		margin-top: 12rem;
	}

	.scrollContainer * {
		margin: 0 1rem;
	}

	.navItem {
		font-size: 6rem;
	}

	.navTag {
		width: 10rem;
		height: 10rem;
		font-size: 1.4rem;
	}

	.paragraph7 {
		font-size: 1.4rem;
	}
}

@media only screen and (max-width: 400px) {
	.quote {
		font-size: 3rem;
		line-height: 5rem;
		width: 95%;
		padding: 0;
	}

	.paragraph3 {
		font-size: 1.6rem;
		line-height: 3rem;
	}

 	.paragraph4 {
		font-size: 2.1rem;
		line-height: 3rem;
	}

	.part__samen {
		padding: 0;
	}

	.part__nav {
		height: 25rem;
	}

	.navItem {
		font-size: 5rem;
	}
}

@media only screen and (max-width: 375px) {
	.subtitle {
		font-size: 26vw;
		margin-bottom: 7rem;
	}

	.paragraph7 {
		font-size: 1.2rem;
		width: 100%;
	}
}

