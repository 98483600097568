.main {
	display: flex;
	margin-top: 21.5rem;
	flex-flow: column;
}

.header {
	display: grid;
	margin-top: 6rem;
}

.nav {
	margin-left: 11.5rem;
	width: fit-content;
	height: fit-content;
	display: flex;
	flex-flow: row;
	align-items: center;
}

.title {
	grid-row: 1;
	grid-column: 1;
	color: white;
	font-family: melodrama;
	letter-spacing: .03em;
	color: var(--red);
	font-size: 4rem;
	font-weight: 400;
}


.navLine {
	width: .1rem;
	height: 5.2rem;
	margin-left: 3.4rem;
	margin-right: 5.6rem;
	border-right: white solid .1rem;
}

.menu {
	display: flex;
	gap: 2.5rem;
}

.navItem {
	display: grid;
	position: relative;
	row-gap: 1.2rem;
	justify-items: center;
}

.navItem svg {
	grid-row: 1;
	grid-column: 1;
	z-index: 1;
	width: 7.5rem;
	height: 7.5rem;
}

.navButton {
	grid-row: 1;
	grid-column: 1;
	height: inherit;
	width: inherit;
	border: none;
	background-color: transparent;
	outline: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.navItem :hover {
	cursor: pointer;
	background-color: var(--blue);
}

.navItem:hover .navButton {
	cursor: pointer;
	background-color: transparent;
}

.navItem__active .navButton {
	/* background-color: var(--blue); */
}

.navItem__active .tag {
	color: white;
}

.tag {
	font-family: satoshi;
	font-weight: 300;
	letter-spacing: .1em;
	font-size: 1.2rem;
	color: #93A2BE;
	transition: .2s ease;
	text-align: center;
}

.hidden {
	opacity: 0;
}

@media only screen and (max-width: 890px) {
	.main {
		margin-top: 10rem;
	}

	.nav {
		flex-flow: column;
		gap: 2rem;
		margin: 0;
		align-self: center;
	}

	.navLine {
		display: none;
		width: 5.2rem;
		height: .1rem;
		border-bottom: .1rem white solid;
		border-right: none;
		margin-bottom: .5rem;
	}
}

@media only screen and (max-width: 600px) {
	.menu {
		gap: 4vw;
	}
}


@media only screen and (max-width: 400px) {
	.menu {
		/* gap: 1.5vw; */
	}

	.navItem svg {
		width: 6rem;
	}
}

