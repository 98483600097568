html {
	height: 100vh;
}

.main {
	margin-top: 6.6rem;
}

.section1 {
	display: flex;
	justify-content: center;
}

.title {
	flex: 1;
	text-align: center;
	font-size: 32rem;
	line-height: 38rem;
	font-family: melodrama;
	letter-spacing: .05em;
	opacity: .1;
	align-self: center;
	justify-self: center;
	margin-top: 6rem;
}

.container {
	position: relative;
	width: 155rem;
	padding: 0 4rem;
	margin: auto;
	z-index: 10;
	/* margin-top: -5rem; */
}

.section2{
	display: grid;
}

.article {
	display: flex;
}

.article1 {
	justify-content: center;
	grid-column: 1/3;
}

.sessieBooking {
	grid-column: 1/3;
	justify-self: center;
	background: white;
	border-radius: 5.7rem;
	outline: none;
	border: none;
	width: 26rem;
	height: 7rem;
	font-family: melodrama;
	font-size: 2.4rem;
	letter-spacing: .02em;
}

.bookLink {
	color: black;
	text-decoration: none;
}

.article2 {
	grid-row: 2;
	grid-column: 1;
	flex-direction: column;
	justify-content: flex-end;
	margin-top: 4rem;
}

.vraagContainer {
	width: fit-content;
	align-self: center;
	display: none;
	/* display: grid; */
}

.vraagTitel {
	font-family: sentient;
	font-size: 3rem;
	letter-spacing: .075em;
	font-weight: 100;
	font-style: italic;
	text-transform: uppercase;
}

.textarea {
	width: 30vw;
	height: 14rem;
	margin-top: 1.4rem;
	margin-left: 4rem;
	padding-top: 1rem;
	padding-left: 1rem;
	font-size: 1.6rem;
	background-color: transparent;
	outline: none;
	border: .1rem solid var(--blue);
}

.email {
	margin-top: 2.5rem;
	margin-left: 4rem;
	width: 60%;
}

.email input {
	width: 100%;
	padding-left: 1rem;
	background: transparent;
	outline: none;
	border: none;
	border-bottom: .1rem solid var(--blue);
	font-size: 1.6rem;
	line-height: 175%;
}

.vraagSubmit {
	width: 8rem;
	height: 8rem;
	background-color: var(--blue);
	border-radius: 50%;
	outline: none;
	border: none;
	margin-top: -4rem;
	margin-right: 12.5%;
	justify-self: flex-end;
}

.copyright {
	/* align-self: center; */
	margin-top: 5rem;
}

.copyrightLabel {
	margin-top: -1rem;
	font-size: 1.6rem;
	letter-spacing: .05em;
}

.article3 {
	grid-row: 2;
	grid-column: 2;
	margin-top: 10.5rem;
}

.info__menu {
	flex: 1;
	display: flex;
	justify-content: space-between;
}

.infoContainer {

}

.infoContainer2 {
	margin-top: 2.1rem;
}

.info__item {
	margin-left: 20rem;
}

.info__item2 {
	margin-left: 0;
	margin-right: 10rem;
}

.info__tag {
	font-family: melodrama;
	font-size: 1.8rem;
	line-height: 3.15rem;
}

.piece {
	font-size: 2.5rem;
	line-height: 4.375rem;
	letter-spacing: .02em;
	font-weight: 500;
	word-break: break-word;
}

@media only screen and (max-width: 1660px) {
	.container {
		width: 140rem;
	}
}

@media only screen and (max-width: 1400px) {
	.container {
		width: 115rem;
	}

	.info__item {
		margin-left: 5rem;
	}

	.info__item2 {
		margin-right: 5rem;
		margin-left: 0;
	}
}

@media only screen and (max-width: 1250px) {
	.container {
		width: 102.4rem;
		margin-top: -5rem;
	}

	.article3 {
		margin-top: 5rem;
	}

	.info__menu {
		flex-flow: column;
	}

	.info__item2 {
		margin-left: 40rem;
		margin-right: -10rem;
	}
}

@media only screen and (max-width: 1050px) {
	.title {
		margin-top: 5rem;
		font-size: 28vw;
		line-height: 30vw;
	}

	.sessieBooking {
		margin-top: 5rem;
	}
}

@media only screen and (max-width: 1024px) {
	.container {
		width: 70rem;
	}

	.section2 {
		display: flex;
		flex-flow: column;
	}

	.article2 {
		/* margin-top: 10rem; */
		order: 2;
		justify-content: center;
	}

	.vraagContainer {
		width: 100%;
	}

	.textarea {
		width: 75%;
	}

	.article3 {
		margin-top: 10rem;
	}

	.info__menu {
		flex-flow: row;
	}

	.info__item {
		margin-left: 0rem;
		margin-right: 0;
	}

	.copyright {
		margin-top: 9rem;
	}
}

@media only screen and (max-width: 890px) {
	.container {
		margin-top: 0;
	}
}

@media only screen and (max-width: 744px) {
	.container {
		width: 60rem;
	}

	.info__menu {
		flex-flow: column;
		margin-left: 5rem;
	}
}

@media only screen and (max-width: 600px) {
	.container {
		width: fit-content;
		padding: 0 2rem;
		width: 90%;
	}

	.sessieBooking {
		margin-top: 3rem;
	}

	.vraagContainer {
	}

	.textarea {
		width: 90%;
		margin-left: 0;
	}

	.piece {
		font-size: 2.1rem;
		line-height: 4.2rem;
		word-break: break-word;
	}

	.vraagSubmit {
		justify-self: flex-end;
		margin-top: -4rem;
	}

	.info__menu {
		margin-left: 0;
	}
}

@media only screen and (max-width: 400px) {
	.title {
		font-size: 25vw;
	}

	.sessieBooking {
		width: 100%;
	}

	.article {
		width: 100%;
	}
}

@media only screen and (max-width: 375px) {
	.textarea {
		height: 30vh;
	}
}

