.header {
	display: flex;
	justify-content: center;
}

.title {
	display: none;
  font-family: satoshi;
	letter-spacing: .1em;
	font-size: 5rem;
	text-transform: uppercase;
	font-weight: 600;
	color: var(--blue);
	margin-top: 8.1rem;
}

.praktisch__main {
	display: grid;
	font-size: 2.1rem;
	letter-spacing: .02em;
	color: black;
}

.nav {
	position: fixed;
	z-index: 2;
	left: 7rem;
	bottom: 5rem;
	opacity: 100;
	transition: .5s ease-in-out;
	grid-row: 1;
	grid-column: 1;
	background-color: white;
	height: 25rem;
	width: 25rem;
	border-radius: 50%;
	display: grid;
	justify-content: center;
	align-items: center;
}

.absolute {
	position: absolute;
	top: 65rem;

}

.fixed {
	position: fixed;
	top: 42.5rem;
}

.nav__menu {
	display: grid;
	height: max-content;
	row-gap: 2.2rem;
}

.navBg {
	display: none;
}

.nav__item {
	font-size: 1.8rem;
	font-family: renomono;
	transition: .5s ease-in-out;
	letter-spacing: .05em;
}

.nav__item a {
	text-decoration: none;
	color: black;
}

.nav__itemActive {
	font-size: 2.1rem;
	font-weight: bold;
	font-family: satoshi;
	font-style: italic;
	text-transform: uppercase;
}

/* .nav__itemWaar {
	font-family: Ranade;

}

.nav__itemWat {
	font-family: Sasoshi;
}

.nav__itemHoe {
	font-family: Sentient;
}

.nav__itemPrijs {
	font-family: playfair-display;
	font-weight: 600;
} */

.navTransformer {
	height: 90vh;
	grid-row: 1;
	grid-column: 1;
}

.hidden {
	background-color: palevioletred;
}

.content {
	grid-column: 1;
	grid-row: 1;
	margin-top: 21rem;
}

.content h3 {
	display: none;
}

.section {
}

.subtitleContainer {
	display: flex;
}

.subtitleContainer__grid {
	flex-direction: column;
	margin: 0 10rem;
}

.subtitle {
	grid-column: 1;
	grid-row: 1;
	font-size: 30rem;
	letter-spacing: .1em;
	text-transform: uppercase;
	font-weight: 600;
	opacity: .05;
}

.subtitle__info {
	font-size: 32rem;
	letter-spacing: .01em;
	font-family: melodrama;
	line-height: 30rem;
	opacity: .1;
	text-align: center;
}

.subtitleWatBack {
	align-self: flex-end;
}

.subEmphasis {
	/* position: initial; */
	letter-spacing: .05em;
	line-height: 30rem;
}

/* Waar */

.waar {
	display: grid;
	grid-auto-rows: max-content;
	position: relative;
	font-family: melodrama;
}

.waar__adres {
	grid-row: 1;
	grid-column: 2;
	text-transform: uppercase;
	margin-right: 8rem;
	margin-top: 5rem;
	font-weight: 300;
	font-size: 12rem;
	letter-spacing: .05em;
	line-height: 21rem;
	display: grid;
	grid-auto-rows: min-content;
	color: black;
	justify-content: flex-start;
}

.waar__adresLarge {
	grid-row: 1;
	grid-column: 1/3;
	font-size: 100rem;
	font-weight: 600;
	position: absolute;
	text-transform: uppercase;
	opacity: .03;
	line-height: 79%;
	z-index: -1;
	/* animation: scrollText 50s infinite linear; */
}

.adresIcon {
	grid-column: 1;
	grid-row: 1;
	justify-self: flex-end;
	margin-left: 5rem;
	margin-top: 2rem;
}

@keyframes scrollText {
  from   { transform: translateX(0%); }
  to { transform: translateX(-50%); }
}

.straat {
	font-size: 17rem;
	text-align: end;
	font-weight: 600;
}

.adresNr {
	text-align: center;
}

.plaats {
	font-size: 18rem;
	justify-self: flex-end;
	font-weight: 600;
}

.waar1 {
	display: grid;
	/* margin: auto; */
	/* justify-self: center; */
	margin-top: -2rem;
	overflow: hidden;
	position: relative;
	height: 80rem;
	column-gap: 5rem;
}

.strong {
	text-transform: uppercase;
	font-weight: 700;
}

.waar2 {
	display: grid;
	grid-template-rows: max-content;
	grid-auto-columns: min-content;
	justify-content: center;
	column-gap: 20rem;
	row-gap: 10rem;
	margin-top: 12rem;
}

.waar__info {
	max-width: 69rem;
	margin-top: 9rem;
	margin-left: 14.4rem;
}

.infoCopy {
	display: flex;
	flex-direction: column;
	row-gap: 3rem;
	font-family: satoshi;
}

.infoCopy__auto {
	grid-column: 1;
}

.infoCopy__fiets {
	grid-column: 2;
}

.infoCopy h4 {
	font-family: melodrama;
	font-size: 3rem;
	font-weight: 100;
	color: var(--blue);
	font-weight: 500;
	letter-spacing: .02em;
}

.infoCopy p {
	line-height: 1.75em;
}

.copyAuto {
	width: 45rem;
}

.copyFiets {
	width: 40rem;
}

.infoCopy b {
	font-family: Sentient;
	font-weight: 600;
}

.imgContainer {
}

.waar__img {
	width: 60rem;
	height: 80rem;
	object-fit: cover;
}

.waar__img2 {
	z-index: 1;
}

.waar__img1 {
	z-index: 0;
}

/* Prijs */

.prijs {
	margin-top: 20rem;
	display: grid;
	flex-direction: column;
	position: relative;
}

.prijsBg {
	grid-row: 1;
	grid-column: 1;
	height: 136.8rem;
	display: grid;
	grid-auto-flow: column;
	column-gap: 1rem;
}

.prijsBg__part {
	background-color: var(--blue);
}

.prijsContent {
	grid-row: 1;
	grid-column: 1;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 1rem;
}

.prijsTitle {
	font-family: melodrama;
	display: grid;
	justify-content: center;
	align-items: center;
	grid-column: 1/3;
}

.prijsNr {
	font-size: 40rem;
	font-weight: 500;
	grid-row: 1;
	grid-column: 1;
	justify-self: center;
	color: var(--beige);
	z-index: 2;
}

.prijsPer {
	font-size: 30rem;
	grid-row: 1;
	grid-column: 1;
	z-index: 0;
	opacity: .10;
	margin-bottom: -20rem;
	color: white;
}

.prijsIcon {
	grid-row: 1;
	grid-column: 1;
	margin-left: 15rem;
	z-index: 1;
}

.sessie {
	display: grid;
	grid-auto-rows: max-content;
	justify-items: center;
}

.sessieMeer {
	display: none;
	grid-row: 1;
	grid-column: 1;
}

.sessieIcon {
	grid-row: 1;
	grid-column: 1;
}

.activeIcon {
	height: min-content;
}

.sessiePersoonlijk {
	grid-column: 1;
}

.sessieGroep {
	grid-column: 2;
}

.sessieTitle {
	font-family: melodrama;
	color: var(--beige);
	font-size: 4rem;
	font-weight: 500;
	margin-top: 1.5rem;
	margin-bottom: 2rem;
}

.sessieSplit {
	/* height: .1rem; */
	border-bottom: white solid .1rem;
	width: 25rem;
}

.sessieCopy {
	font-size: 2.1rem;
	line-height: 3.2rem;
	color: white;
}

.persoonlijkCopy {
	width: 42rem;
	text-align: center;
}

.groepCopy2 {
	font-weight: 500;
}

.sessieBooking {
	grid-column: 1/3;
	justify-self: center;
	background: white;
	border-radius: 5.7rem;
	outline: none;
	border: none;
	width: 26rem;
	height: 7rem;
	font-family: melodrama;
	font-size: 2.4rem;
	letter-spacing: .02em;
}

.bookLink {
	color: black;
	text-decoration: none;
}

/* Wat */

.wat {
	display: flex;
	flex-flow: column;
	column-gap: 8rem;
	margin-top: 20rem;
}

.copytitle {
	color: var(--red);
	font-size: 4rem;
	font-family: satoshi;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: .05em;
}

.wat__quote {
	grid-column: 1/3;
	justify-self: center;
	margin-bottom: 6.8rem;
}

.watInfo {
	display: flex;
	justify-content: space-evenly;
	margin-top: -18rem;
}

.wat__part {
	z-index: 1;
	position: relative;
	display: flex;
	flex-flow: column;
	row-gap: 2rem;
	justify-content: center;
	justify-items: center;
}

.wat__circle {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 58rem;
	width: 58rem;
	background-color: white;
	border-radius: 50%;
}

.watTags {
	display: grid;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
}

.watTags p {
	font-family: melodrama;
	letter-spacing: .05em;
	font-size: 5rem;
	font-weight: 500;
	grid-column: 1;
	grid-row: 1;
	z-index: 2;
}

.tagIcon {
	grid-row: 1;
	grid-column: 1;
	display: flex;
	justify-content: center;
	z-index: 1;
}

.tagIcon svg {
	width: 12rem;
	height: 12rem;
}

.wat__circle img {
	width: 57.4rem;
}

.uitleg {
	font-family: justme;
	font-size: 4rem;
	position: absolute;
	display: flex;
	flex-flow: column;
	align-items: center;
}

.uitleg1 {
	bottom: -7rem;
}

.uitleg2 {
	bottom: -5rem;
	right: 5rem;
}

.uitleg2 p {
	position: absolute;
	right: -11rem;
	bottom: -1rem;
}

.uitleg3 {
	bottom: -10rem;
	left: 50%;
	transform: translateY(-50%);
}

.uitleg3 p {
	position: absolute;
	width: max-content;
	bottom: -5rem;
	left: 2rem;
}

.watCopy {
	margin: 0 14vw;
	margin-top: 29rem;
	display: flex;
	flex-flow: column;
	row-gap: 10rem;
}

.copyPart {
	display: grid;
	grid-auto-columns: max-content;
	column-gap: 1rem;
	row-gap: 1.2rem;
}

.watCopy__part2 {
	align-self: flex-end;
}

.watCopy__part3 {
	margin: 5rem 0;
	margin-left: 10rem;
}

.watCopy__part4 {
	align-self: flex-end;
	margin-right: 15rem;
}

.copyIndex {
	grid-row: 1;
	grid-column: 1;
}

.copyStrong {
	font-family: melodrama;
	font-size: 3rem;
	font-weight: 500;
	letter-spacing: .05em;
	line-height: 3.8rem;
	grid-column: 2;
}

.copy {
	line-height: 160%;
	font-family: satoshi;
	grid-column: 2;
}

.vergeten {
	width: 48rem;
}

.vergeten b {
	font-weight: 800;
}

.douchen {
	width: 55rem;
}

.olieStrong {
	font-size: 8rem;
	width: 60vw;
	line-height: normal;
}

.hoeNaSessie {
	width: 33.5vw;
}

.naSessieStrong {
	width: 38vw;
}


/* Hoe */

.hoe {
	margin-top: 20rem;
	display: flex;
	flex-flow: column;
}

.subtitleHoe__icon {
	align-self: center;
	width: 14rem;
	margin-top: -10rem;
	z-index: 1;
}

.copytitle__hoe {
	grid-row: 2;
}

.hoeCopy {
	display: flex;
	margin: 0 14vw;
	flex-flow: column;
	margin-top: 15rem;
}

.hoeCopy__part2 {
	align-self: flex-end;
}

.hoe b {
	font-weight: 600;
}

.hoeReflexStrong {
	width: 63rem;
}

.hoeReflex {
	width: 53rem;
	line-height: 1.75em;
}

.hoeMassageStrong {
	width: 55rem;
}

.hoeMassage {
	width: 52rem;
	line-height: 1.75em;
}

@media only screen and (max-width: 1660px) {
	.nav {
		width: 23rem;
		height: 23rem;
	}

	.nav__menu {
		row-gap: 2rem;
	}

	.content {
		width: 100%;
		margin-top: 18rem;
	}

	.subtitleContainer {
		margin: 0;
		margin-left: -4rem;
	}

	.subtitle__info {
		font-size: 25rem;
		line-height: 20rem;
	}

	.straat {
		font-size: 14rem;
	}

	.plaats {
		font-size: 15rem;
	}

	.waar__adres {
		font-size: 11rem;
		line-height: 18rem;
		margin-right: 5rem;
		margin-top: 10rem;
	}

	.adresIcon {
		margin-top: 5rem;
	}

	.adresIcon svg {
		width: 15rem;
	}

	.waar2 {
		column-gap: 10rem;
	}

	.waar__img {
		width: 55rem;
	}

	.sessieIcon svg {
		width: 25rem;
	}

	.watInfo {
		margin-top: -10rem;
	}

	.wat__circle {
		height: 50rem;
		width: 50rem;
	}

	.wat__circle img {
		width: 48rem;
	}

	.wat__part3 {
		margin-left: 5rem;
	}

	.wat__part4 {
		margin-left: 12rem;
	}

	.hoeCopy {
		margin: 0 10vw;
		margin-top: 15rem;
	}
}

@media only screen and (max-width: 1400px) {
	.praktisch__main {
		font-size: 2rem;
	}

	.content {
		margin-top: 16rem;
	}

	.copyStrong {
		font-size: 2.6rem;
	}

	.olieStrong {
		font-size: 6rem;
	}

	.waar1 {
		column-gap: 2rem;
	}

	.adresIcon  {
		margin-left: 4rem;
	}

	.waar__adres {
		font-size: 10rem;
		justify-self: center;
	}

	.straat {
		font-size: 12rem;
	}

	.plaats {
		font-size: 13rem;
	}

	.waar__img {
		width: 40vw;
		height: 75rem;
	}

	.prijsNr {
		font-size: 35rem;
	}

	.prijsPer {
		font-size: 25rem;
	}

	.prijsIcon {
		margin-left: 10rem;
	}

	.sessieCopy {
		font-size: 2rem;
		line-height: 3rem;
	}

	.wat {
		margin-top: 15rem;
	}

	.subtitleWatBack {
		align-self: center;
	}

	.wat__circle {
		height: 45rem;
		width: 45rem;
	}

	.wat__circle img {
		width: 42rem;
	}

	.douchen {
		width: 50rem;
	}

	.hoeCopy {
		margin-left: 7vw;
		margin-right: 7vw;
	}

	.hoeReflexStrong {
		width: 50rem;
	}

	.hoeMassage {
		width: 45rem;
	}
}

@media only screen and (max-width: 1250px) {
	.section {
	}

	.subtitleContainer {
		margin-left: -6rem;
	}

	.subtitle {
		font-size: 20rem;
	}

	.subtitle__info {
		font-size: 20rem;
	}

	.waar1 {
		grid-auto-flow: row;
	}

	.waar__adres {
		grid-row: 2;
		grid-column: 1;
		margin-top: -20rem;
		margin-right: 0;
		font-size: 10vw;
		line-height: 18rem;
		justify-self: center;
	}

	.straat {
		/* max-width: 85vw; */
		word-wrap: break-word;
		text-align: start;
		font-size: 11vw;
	}

	.plaats {
		font-size: 12vw;
	}

	.adresIcon {
		justify-self: center;
		margin-top: 0;
		margin-left: 0;
	}

	.infoCopy {
		font-size: 2.1rem;
		/* margin-left: 0; */
	}

	.copyAuto {
		width: 34rem;
	}

	.copyFiets {
		width: 28rem;
	}

	.waar2 {
		column-gap: 5rem;
	}

	.waar__img {
		height: 60rem;
		justify-self: center;
	}

	.persoonlijkCopy {
		width: 35rem;
	}

	.wat__part3 {
	}

	.vergetenStrong {
		width: 30rem;
	}

	.wat__part4 {
		justify-self: flex-end;
		grid-column: 1/3;
		margin-right: 5rem;
		margin-left: 0;
	}

	.douchenStrong {
		width: 35.6vw;
	}

	.hoeReflex {
		width: 45rem;
	}

	.hoeReflexStrong {
		width: 40rem;
	}

	.hoeMassage {
		width: 45rem;
	}

	.hoeMassageStrong {
		width: 40rem;
	}
}

@media only screen and (max-width: 1050px) {
	.section {
		margin-left: 0;
	}

	.subtitleContainer {
		margin-left: 0;
		justify-self: center;
	}

	.nav {
		width: 20rem;
		height: 20rem;
		left: 5rem;
	}

	.nav__menu {
		row-gap: 1.5rem;
	}

	.nav__item {
		font-size: 1.6rem;
	}

	.nav__itemActive {
		font-size: 1.8rem;
	}

	.waar1 {
		height: 70rem;
	}

	.waar__adres {
		line-height: 15rem;
	}

	.waar__adresLarge {
		font-size: 70rem;
		line-height: 110%;
		margin-top: 3rem;
	}

	.adresIcon svg {
		width: initial;
	}

	.waar2 {
		column-gap: 0;
	}

	.copyAuto {
		width: 36rem;
	}

	.copyFiets {
		width: 35rem;
	}

	.infoCopy__auto {
	}

	.waar__img2 {
		margin-left: 7rem;
	}

	.infoCopy__fiets {
		grid-column: 1;
		grid-row: 3;
		margin-top: 10rem;
	}

	.waar__img1 {
		margin-right: 7rem;
	}

	.waar__img {
		height: 75rem;
		width: 60rem;
	}

	.prijsNr {
		font-size: 32.5rem;
	}

	.prijsPer {
		font-size: 22.5rem;
	}

	.prijsIcon {
		margin-left: 7.5rem;
	}

	.watCopy {
		margin-left: 10vw;
		margin-right: 10vw;
	}

	.wat__circle {
		width: 38rem;
		height: 38rem;
	}

	.wat__circle img {
		width: 35rem;
	}

	.naSessieStrong {
		width: 43rem;
	}

	.hoeNaSessie {
		width: 40rem;
	}

	.hoe {
		row-gap: 0;
	}

	.hoeCopy {
		row-gap: 5rem;
	}

	.hoe__part1 {
		justify-self: auto;
		margin-left: 15rem;
	}

	.hoe__part2 {
		grid-column: 1;
		grid-row: 3;
		justify-self: flex-end;
		margin-right: 10rem;
		margin-top: 2rem;
	}
}

@media only screen and (max-width: 890px) {
	.waar__adres {
		line-height: 16.8vw;
	}

	.straat {
		max-width: 85vw;
		font-size: 10.5vw;
	}

	.plaats {
		font-size: 10.5vw;
	}

	.waar2 {
		margin-top: 0;
	}

	.infoCopy__auto {
		margin-left: 5rem;
	}

	.infoCopy__fiets {
		margin-left: 5rem;
	}

	.waar__img {
		height: 75rem;
		width: 80vw;
	}

	.waar__img1 {
		margin-right: 0;
		margin-left: 7rem;
	}

	.prijsNr {
		font-size: 30rem;
	}

	.prijsPer {
		font-size: 25vw;
	}

	.prijsIcon {
		margin-left: 0;
		justify-self: center;
		align-self: flex-start;
		margin-top: 8rem;
	}

	.prijsIcon svg {
		width: 15rem;
	}

	.sessieTitle {
		font-size: 3rem;
	}

	.sessieCopy {
		font-size: 1.8rem;
		line-height: 2.6rem;
	}

	.sessieBooking {
		width: 20rem;
		height: 6rem;
		font-size: 2.1rem;
	}

	.persoonlijkCopy {
		width: 25rem;
	}

	.wat {
		margin-top: 15vw;
	}

	.subtitle__info {
		font-size: 20vw;
		line-height: 20vw;
	}

	.watInfo {
		flex-direction: column;
		row-gap: 5rem;
		margin-top: -10vw;
	}

	.wat__part {
		row-gap: 0;
	}

	.wat__circle {
		width: 35rem;
		height: 35rem;
		justify-self: center;
		align-self: center;
		margin-top: -8rem;
	}

	.tagIcon svg {
		height: initial;
		width: initial;
	}

	.wat__circle img {
		width: 32rem;
	}

	.uitleg {
		font-size: 3rem;
	}

	.uitleg img {
		display: none;
	}

	.uitleg1 {
		left: 35%;
		transform: translateX(-35%);
		bottom: 3rem;
	}

	.uitleg2 {
		right: initial;
		left: 60%;
		transform: translateX(-60%);
		bottom: 10rem;
	}

	.uitleg3 {
		bottom: 10rem;
	}

	.watCopy {
		margin-top: 10rem;
		margin-left: 7vw;
		margin-right: 7vw;
	}

	.watCopy__part3 {
		margin: 0;
		margin-left: 5rem;
	}

	.watCopy__part4 {
		margin-right: 5rem;
	}

	.copy {
		font-size: 1.8rem;
	}

	.copyStrong {
		font-size: 2.4rem;
	}

	.vergeten {
		width: 40rem;
	}

	.douchen {
		width: 40rem;
	}

	.douchenStrong {
		width: 30rem;
	}

	.olieStrong {
		font-size: 4rem;
		width: 40rem;
	}

	.hoeNaSessie {
		width: 43rem;
	}

	.subtitleHoe__icon {
		width: 16vw;
		margin-top: -11vw;
	}

	.hoeCopy {
		margin-top: 15vw;
	}

	.hoe__part2 {
		margin-top: 5rem;
	}

	.hoe__part4 {
		margin-right: 10rem;
	}
}

@media only screen and (max-width: 600px) {
	.nav {
		bottom: 3rem;
		left: 3rem;
	}

	.content {
		margin-top: 10rem;
	}

	.waar1 {
		height: 50rem;
	}

	.waar__adres {
		margin-top: -15rem;
	}

	.waar__adresLarge {
		font-size: 50rem;
	}

	.adresIcon svg {
		width: 12rem;
	}

	.waar2 {
		margin-top: 5rem;
	}

	.infoCopy__auto {
		margin-left: 0;
	}

	.infoCopy__fiets {
		margin-left: 0;
	}

	.waar__img {
		width: 90vw;
		height: 70vh;
	}

	.waar__img1 {
		margin: 0;
	}

	.waar__img2 {
		margin: 0;
	}

	.prijsBg {
		column-gap: 0;
		height: auto;
	}

	.prijsBg__part {
		grid-column: 1;
		grid-row: 1;
	}

	.prijsContent {
		grid-template-columns: auto auto;
		column-gap: 0;
		margin: 0 5vw;
		padding-bottom: 12rem;
	}

	.sessie {
		margin: 25vw 0;
	}

	.sessiePersoonlijk {
		justify-content: center;
	}

	.sessieGroep {
		justify-content: center;
	}

	.sessieMeer {
		display: initial;
		justify-self: center;
		align-self: center;
		font-size: 1.2rem;
		font-style: italic;
		text-transform: uppercase;
		letter-spacing: .05em;
		width: 6.5rem;
		height: 6.5rem;
		border-radius: 50%;
		background-color: var(--beige);
		border: none;
		outline: none;
		opacity: .85;
	}

	.meerHidden {
		display: none;
	}

	.sessieIcon svg {
		width: 38vw;
		height: 25vw;
	}

	.activeIcon svg {
		width: 45vw;
		height: 33vw;
	}

	.sessieSplit {
		display: none;
		width: 25vw;
	}

	.sessieTitle {
		font-size: 2.1rem;
		word-wrap: break-word;
		text-align: center;
		letter-spacing: .05em;
		justify-self: center;
	}

	.activeTitle {
		font-size: 2.1rem;
		width: max-content;
	}

	.sessieCopy {
		display: none;
	}

	.persoonlijkCopy {
		width: auto;
	}

	.activeCopy {
		display: initial;
	}

	.activeSplit {
		display: initial;
		grid-row: 3;
		margin-bottom: 2.5rem;
		width: 30vw;
	}

	.watTags p {
		overflow-wrap: break-word;
		width: 25rem;
		text-align: center;
	}

	.watCopy {
		margin-left: 5vw;
		margin-right: 5vw;
	}

	.watCopy__part3 {
		margin-left: 0;
	}

	.watCopy__part4 {
		margin-right: 0;
	}

	.vergeten {
		width: 34rem;
	}

	.douchen {
		width: 34rem;
	}

	.olieStrong {
		width: 80vw;
	}

	.hoeNaSessie {
		width: 34rem;
	}

	.naSessieStrong {
		width: 32rem;
	}

	.subtitleHoe__icon {
		width: 10rem;
		margin-top: -11vw;
	}

	.hoeReflex {
		width: 80vw;
	}

	.hoeReflexStrong {
		width: 35rem;
	}

	.hoeMassage {
		width: 80vw;
	}

	.hoeMassageStrong {
		width: 35rem;
	}
}

@media only screen and (max-width: 400px) {
	.infoCopy {
		justify-self: center;
		font-size: 1.8rem;
		width: 80vw;
	}

	.infoCopy__auto {
		font-size: 1.8rem;

	}

	.copyAuto {
		width: 80vw;
	}

	.infoCopy__fiets {
		font-size: 1.8rem;
	}

	.copyFiets {
		width: 80vw;
	}

	.prijsNr {
		font-size: 25rem;
	}

	.sessieTitle {
		font-size: 1.6rem;
	}

	.activeTitle {
		font-size: 1.8rem;
		width: max-content;
	}

	.sessieCopy {
		font-size: 1.4rem;
		line-height: 2.2rem;
	}

	.activeSplit {
		margin-bottom: 1.5rem;
	}

	.sessieMeer {
		width: 5rem;
		height: 5rem;
		font-size: 1.1rem;
	}

	.sessieBooking {
		width: 18rem;
		height: 5.5rem;
		font-size: 2rem;
	}

	.watInfo {
		row-gap: 2rem;
	}

	.subtitleContainer {
		font-weight: 400;
	}

	.tagIcon svg {
		width: 12rem;
		height: 12rem;
	}

	.wat__circle {
		width: 87.5vw;
		height: 87.5vw;
	}

	.wat__circle svg {
		height: 83vw;
		width: 83vw;
	}

	.uitleg {
	}

	.watCopy {
		margin-left: 1.5rem;
		margin-right: 1.5rem;
	}

	.copy {
		font-size: 1.6rem;
		width: 80vw;
	}

	.copyStrong {
		font-size: 2.1rem;
		line-height: 3.2rem;
		width: 80vw;
	}

	.olieStrong {
		font-size: 4rem;
		line-height: 4.8rem;
	}

}
