.header {
	composes: header from './Yoga.module.css';
	grid-column: 1/3;
	margin-bottom: 10rem;
}

.title {
	composes: title from './Yoga.module.css';
}

.content {
	composes: content from './Yoga.module.css';
	column-gap: 5vw;
}

.textPart {
	display: flex;
	flex-flow: column;
	row-gap: 2rem;
}

.textPart1 {
	margin-left: 1vw;
	grid-row: 2;
	grid-column: 1;
}

.textPart2 {
	grid-row: 2;
	grid-column: 2;
}

.sub {
	composes: sub from './Yoga.module.css';
}

.paragraph {
	composes: paragraph from './Yoga.module.css';
}

.sub1 {
	width: 52rem;
}

.paragraph1 {
	width: 54.7rem;
}

.sub2 {
	width: 52rem;
}

.paragraph2 {
	width: 56.1rem;
}

.link {
	text-decoration: underline;
	font-weight: 300;
	font-size: 2rem;
}

.img__content {
	grid-row: 3;
	grid-column: 1/3;
	display: grid;
	align-items: center;
	justify-content: center;
	justify-items: center;
	/* justify-self: center; */
	overflow: hidden;
	height: 38.5vw;
}

.main__img {
	grid-row: 1;
	grid-column: 1;
	width: 38.5vw;
}

.paint {
	grid-row: 1;
	grid-column: 1;
	mix-blend-mode: luminosity;
	opacity: .5;
	z-index: -1;
	overflow: hidden;
	width: 100vw;
}


@media only screen and (max-width: 1400px) {
	.title {
		font-size: 20rem;
	}

	.text {
		margin-left: 1vw;
	}

	.sub1 {
		width: 42rem;
	}

	.paragraph1 {
		width: 44rem;
	}

	.sub2 {
		width: 40rem;
	}

	.paragraph2 {
		width: 45rem;
	}

	.img__content {
		height: 45vw;
	}

	.main__img {
		width: 45vw;
	}

	.paint {
		width: 130vw;
	}
}

@media only screen and (max-width: 1250px) {
	.text {
		margin-left: 0;
	}
}

@media only screen and (max-width: 1050px) {
	.title {
		font-size: 18rem;
	}

	.header {
		margin-bottom: 8rem;
		grid-column: 1;
	}

	.textPart1 {
		grid-column: 1;
	}

	.textPart2 {
		grid-column: 1;
		grid-row: 3;
		justify-self: flex-end;
		margin-top: 8rem;
	}

	.img__content {
		grid-column: 1;
		grid-row: 4;
	}
}

@media only screen and (max-width: 1024px) {
	.img__content {
		height: 50rem;
	}
}

@media only screen and (max-width: 890px) {
	.header {
		margin-bottom: 5rem;
	}

	.title {
		font-size: 15rem;
	}
}

@media only screen and (max-width: 744px) {
	.title {
		font-size: 12rem;
	}

	.header {
		grid-row: 1;
	}

	.textPart1 {
		grid-row: 2;
	}

	.textPart2 {
		grid-row: 4;
		justify-self: flex-start;
		margin-top: 0;
	}

	.sub {
		width: 75vw;
	}

	.paragraph {
		width: 75vw;
	}

	.img__content {
		grid-row: 3;
	}

	.main__img {
		width: 50vw;
	}

	.paint {
		width: 150vw;
	}

}

@media only screen and (max-width: 600px) {
	.title {
		font-size: 20vw;
	}

	.sub {
		width: 100%;
	}

	.paragraph {
		width: 100%;
	}

	.main__img {
		width: 70vw;
	}

	.paint {
		width: 130vw;
	}
}

@media only screen and (max-width: 400px) {
	.header {
		margin-bottom: 4rem;
	}
}
