.main {
	display: grid;
	height: fit-content;
}

.header {
	margin-top: 10rem;
	margin-bottom: 8rem;
}

.title {
	font-family: melodrama;
	letter-spacing: .05em;
	font-size: 20rem;
	font-weight: 600;
	color: white;
}

.yogaTitle {
	font-size: 25rem;
	margin-bottom: 3rem;
}

.content {
	display: grid;
	margin: 0 9.333vw;
	z-index: 1;
}

.sub {
	font-family: melodrama;
	font-size: 2.8rem;
	letter-spacing: .03em;
	line-height: 4.2rem;
	color: white;
}

.paragraph {
	font-size: 2rem;
	line-height: 3.4rem;
	color: white;
}

/* Part 1 */

.text__part1 {
	grid-row: 2;
	grid-column: 1;
	z-index: 2;
	display: flex;
	flex-flow: column;
	margin-left: 2vw;
	margin-bottom: 15rem;
}

.sub1 {
	width: 53.7rem;
	margin-bottom: 2rem;
}

.paragraph1 {
	width: 64.9rem;
	margin-bottom: 4rem;
}

.paragraph2 {
	width: 59rem;
}

/* Graphics */

.figure {
	grid-column: 2;
	grid-row: 2;
	display: flex;
	flex-flow: column;
	justify-self: center;
	margin-top: -20rem;
}

.figure img {
	width: 60rem;
}

.graphics {
	display: grid;
	grid-template-rows: 46.9rem;
	grid-row: 3;
	grid-column: 1/3;
}

.yinyang {
	grid-row: 1;
	grid-column: 1;
	font-family: justme, sans-serif;
	color: white;
	font-size: 4rem;
	letter-spacing: .05em;
	text-transform: capitalize;
}

.sun {
	grid-row: 1;
	grid-column: 1;
	display: grid;
	position: relative;
}

.graphic__sun {
	grid-row: 1;
	grid-column: 1;
	margin-right: 2.5rem;
	transform: scaleX(-1);
}

.yin {
	position: absolute;
	top: 42rem;
	left: 15rem;
	z-index: 1;
}

.lines {
	grid-row: 1/3;
	grid-column: 1;
	z-index: 1;
	display: none;
	position: relative;
}

.lines__yin {
	grid-column: 1;
	grid-row: 1;
	position: relative;
	left: 35rem;
	top: 40rem;
}

.lines__universe {
	grid-column: 1;
	grid-row: 1;
	position: relative;
	top: 50rem;
	left: 18rem;
}

.moon {
	grid-row: 2;
	grid-column: 1;
	justify-self: flex-end;
	display: grid;
	margin-top: 15rem;
	position: relative;
}

.graphic__moon {
	grid-column: 1;
	grid-row: 1;
	transform: scaleX(-1);
}

.yang {
	z-index: 2;
	grid-column: 1;
	grid-row: 1;
	position: absolute;
	left: 30rem;
	top: 18rem;
}

/* Part 2 */

.text__part2 {
	z-index: 2;
	grid-row: 3;
	grid-column: 2;
	display: flex;
	flex-flow: column;
	justify-self: flex-end;
	margin-right: 7vw;
	margin-top: 5rem;
}

.subTitle {
	font-size: 5rem;
	letter-spacing: .1em;
	font-family: melodrama;
	font-weight: 400;
	color: white;
	margin-bottom: 4rem;
}

.paragraph3 {
	margin-bottom: 4.5rem;
	width: 52.3rem;
}

.paragraph4 {
	width: 51rem;
}

@media only screen and (max-width: 1660px) {
	.figure img {
		width: 52rem;
	}
}

@media only screen and (max-width: 1400px) {

	.paragraph1 {
		width: 44.44vw;
	}

	.paragraph2 {
		width: 40.9vw;
	}

	.paragraph3 {
		width: 37.14vw;
	}

	.paragraph4 {
		width: 36.4vw;
	}

	.figure {
		margin-top: -10rem;
	}

	.figure img {
		width: 39.4vw;
	}
}

@media only screen and (max-width: 1250px) {
	.header {
		margin-bottom: 5rem;
		margin-top: 3rem;
	}

	.content {
		margin: 0 7vw;
	}

	.sub1 {
		width: 45rem;
	}

	.paragraph1 {
		width: ;
	}

	.paragraph2 {
		width: ;
	}

	.paragraph3 {
		width: ;
	}

	.paragraph4 {
		width: ;
	}

	.figure {
		margin-top: 0;
	}

	.graphic__sun {
		width: 45rem;
	}

	.graphic__moon {
		width: 60rem;
	}
}

@media only screen and (max-width: 1050px) {
	.yogaTitle {
		font-size: 20rem;
	}

	.figure {
		justify-content: center;
		margin-top: -5rem;
	}

	.figure img {
		width: 35rem;
	}

	.text__part2 {
		grid-column: 1/3;
		justify-self: center;
		margin-right: -8rem;
	}

	.paragraph1 {
		width: 40rem;
	}

	.paragraph2 {
		width: 40rem;
	}

	.paragraph3 {
		width: 52.3rem;
	}

	.paragraph4 {
		width: 51rem;
	}

	.graphics {
		grid-row: 4;
	}

	.graphic__sun {
		margin-right: 0;
		width: 40rem;
	}

	.graphic__moon {
		width: 55rem;
	}
}

@media only screen and (max-width: 890px) {
	.content {
		margin: 0 8vw;
	}

	.text__part1 {
		grid-column: 1/3;
	}

	.text__part2 {
		margin-right: 0;
	}

	.sub {
		font-size: 2.4rem;
		line-height: 4.2rem;
	}

	.paragraph {
		font-size: 1.8rem;
		line-height: 3.2rem;
	}

	.sub1 {
		width: 38.1rem;
	}

	.paragraph1 {
		width: 38rem;
	}

	.paragraph2 {
		width: 50.1rem;
	}

	.figure {
		grid-column: 1/3;
		justify-self: flex-end;
		margin-right: -4vw;
		justify-content: flex-start;
		margin-top: 2rem;
	}

	.moon {
		margin-top: 10rem;
	}
}

@media only screen and (max-width: 744px) {
	.header {
	}

	.text__part1 {
		margin-bottom: 4rem;
	}

	.sub {
		width: 70vw;
	}

	.paragraph {
		width: 70vw;
	}

	.figure {
		grid-row: 3;
		margin-top: 0;
		justify-self: center;
		margin-right: 0;
	}

	.text__part2 {
		grid-row: 4;
	}

	.graphics {
		grid-row: 5;
	}
}

@media only screen and (max-width: 600px) {
	.header {
		margin-top: 3rem;
		margin-bottom: 4rem;
	}

	.yogaTitle {
		font-size: 15rem;
	}

	.content {
		margin: 0 6.667vw;
	}

	.sub {
		width: 100%;
	}

	.paragraph {
		width: 100%;
	}

	.text__part2 {
		justify-self: flex-start;
	}

	.graphics {
		margin-top: 8rem;
	}

	.graphic__sun {
		width: 30rem;
	}

	.graphic__moon {
		width: 35rem;
	}

	.moon {
		margin-top: 0;
	}

	.yang {
		left: 0;
	}
}

@media only screen and (max-width: 400px) {
	.content {
		margin: 0 2.2rem;
	}

	.yogaTitle {
		font-size: 12rem;
	}

	.figure {
		margin-left: -5vw;
	}

	.figure img {
		width: 90vw;
	}

	.graphic__sun {
		width: 25rem;
	}

	.graphic__moon {
		width: 28rem;
	}
}

@media only screen and (max-width: 375px) {
	.yogaTitle {
		font-size: 10rem;
	}

	.figure {
		overflow: hidden;
	}
}
